import { LicensePage } from 'license-app';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';


const LicenseNumbers = () => {
	return (
		<Layout header={'menu'}>
			<SEO
				title="License Numbers"
				canonicalUrl='https://www.getmybubble.com/license-numbers/'
				description="License Numbers" />

			<div className="flexColumn carrierCommonPage">
				<div className="company-info-container container-fluid">
					<div className="row">
						<div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<div>
								<h1 className="carrier-plan-heading about-heading" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                  License numbers
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-lg-3"></div>

					<LicensePage/>

					<div className="col-lg-3"></div>
				</div>
			</div>

		</Layout>

	);
};

export default LicenseNumbers;
